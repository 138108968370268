import { FC } from 'react'

import { GetAuthors, GetAuthorsVariables } from 'types/graphql'

import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import Authors from '../Library/Authors/Authors'

export const QUERY = gql`
  query GetAuthors($createdById: Int!, $updatedById: Int!) {
    createdByUser: user(id: $createdById) {
      id
      name
      avatarUrl
    }
    updatedByUser: user(id: $updatedById) {
      id
      name
      avatarUrl
    }
  }
`

export const Loading = () => <div>...</div>

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

type SuccessProps = CellSuccessProps<GetAuthors, GetAuthorsVariables> & {
  createdAt: string
  updatedAt: string
}

export const Success: FC<SuccessProps> = (props) => {
  const createdAtDate = new Date(props.createdAt).toLocaleDateString()
  const updatedAtDate = new Date(props.updatedAt).toLocaleDateString()

  return (
    <Authors
      createdByUser={props.createdByUser}
      updatedByUser={props.updatedByUser}
      createdAt={createdAtDate}
      updatedAt={updatedAtDate}
    />
  )
}

// This will be called if the first query above didn't return anything,
// but there still might be data for updatedByUser
export const Empty = Success
